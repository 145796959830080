<template>
	<div>
		<!--顶部工具条-->
		<el-col :span="24">
			<el-form :inline="true">
				<el-form-item label="关键字:">
					<el-input v-model="filters.searchKey" @input="(e) => (filters.searchKey = utils.validForbid(e))" placeholder="请输入项目编号/项目名称" clearable @clear='clearContent'></el-input>
				</el-form-item>
                <el-form-item >
                    <el-select v-model="filters.status" placeholder="受领状态">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
				<el-form-item>
					<toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
				</el-form-item>
			</el-form>
		</el-col>
		<!--列表-->
		<el-table
			:data="dataPer"
			highlight-current-row 
			@current-change="selectCurrentRow"
			v-loading="listLoading"
			@selection-change="selsChange"
			style="width: 100%;"
            :cell-style="rowClass"
		>
			<!-- <el-table-column type="selection" width="50">
			</el-table-column> -->
			<el-table-column type="index" width="80"></el-table-column>
			<el-table-column prop="projectNo" label="项目编号"></el-table-column>
			<el-table-column prop="projectName" label="项目名称"></el-table-column>
			<el-table-column prop="drawingCount" label="图纸数量"></el-table-column>
			<el-table-column prop="assignedUserName" label="图审人员"></el-table-column>
            <el-table-column prop="taskStatusName" label="认领状态"></el-table-column>
			<el-table-column prop="createTime" label="分配时间"></el-table-column>
		</el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.pageIndex"
                :page-sizes="pages.pageArr"
                :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.dataCount"
            >
            </el-pagination>
        </el-col>
        <el-dialog
        title="处理任务"
        :visible.sync="acceptTaskVisible"
        v-model="acceptTaskVisible"
        :close-on-click-modal="false"
        >
            <el-form label-width="150px">
                <el-form-item label="标题：">
                    <div>项目名称：{{currentRow.projectName}}</div>
                </el-form-item>
                <el-form-item>
                    <el-radio-group v-model="viewsData.viwesInformationType">
                        <el-radio :label="104">受领任务</el-radio>
                        <el-radio :label="105">拒领任务：时间安排有冲突，请假拒领</el-radio>
                        <el-radio :label="106">拒领任务：项目特殊，不胜任拒领</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="acceptTaskVisible = false">取消</el-button>
                <el-button @click.native="confirm" :loading="acceptTaskLoading" type="primary">确认</el-button>
            </div>
        </el-dialog>

	</div>
</template>
<script>
import util from '../../../util/date';
import { getTaskApplyList, confirmTask } from '../../api/api';
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import Qs from 'qs'
export default {
	components: { Toolbar },
	data() {
		return {
            utils: '',
			dataPer:[],
			listLoading: false,
			currentRow: {},
			//列表选中列
			sels: [],
			//增删改查按钮
			buttonList: [],
            pages: {  //关于分页的obj
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            //顶部筛选条件
            filters: { 
                name: '',
                status: 103
            },
            addLoading: false,
            options:[
                {label:'待受领',value:103},
                {label:'已受领',value:104}
            ],
            acceptTaskVisible: false,
            acceptTaskLoading: false,
            viewsData: {
                viwesInformationType: 104
            },
		};
	},
	methods: {
		// 筛选框清空，重新获取数据
		clearContent(){
			this.getListData()
		},
		//当前行发生变化时
		selectCurrentRow(val) {
			this.currentRow = val;
		},
		//选项发生变化时
		selsChange: function (sels) {
			this.sels = sels;
		},
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getListData();
        },

        handleSizeChange(val){
            this.pages.pageSize = val
            this.getListData();
        },
        //获取数据权限列表
        getListData(){
            this.listLoading = true;
            let params = {
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            };
            if(this.filters.searchKey){
                params.searchKey = this.filters.searchKey;
            }
            if(this.filters.status){
                params.status = this.filters.status;
            }
            getTaskApplyList(params).then((res) => {
                this.dataPer = res.data.response.data;
                this.listLoading = false;
                    this.pages.dataCount = res.data.response.dataCount
                    this.pages.pageIndex = res.data.response.pageIndex
                    if(res.data.response.pageSize > 0){
                        this.pages.pageSize = res.data.response.pageSize
                    }
            });
        },
        confirm() {
            this.acceptTaskLoading = true
            confirmTask({taskId: this.currentRow.id, taskStatus: this.viewsData.viwesInformationType}).then((res) => {
                let str = "拒领";
                if(this.viewsData.viwesInformationType == 104){
                    str = "受领";
                }
                if(res.data.response){
                    this.acceptTaskVisible = false;
                    this.$message({
                        message: str + '任务成功！',
                        type: 'success'
                    });
                    this.getListData();
                }else{
                    this.$message({
                        message: str + '任务失败！',
                        type: 'error'
                    });
                }
                this.acceptTaskLoading = false
            });
        },
        confirmTaskDialog() {
            if(this.currentRow.id){
                this.acceptTaskVisible = true;
            }else{
                this.$message.error("请先选择任务");
            }
        },
		callFunction(item) {
            if(item.func == "getListData") {
                this.pages.pageIndex = 1
            }
			this[item.func].apply(this, item);
		},
        rowClass({columnIndex}){
            return 'padding:8px!important;'
        },
	},
	mounted() {
        this.utils = util
		this.getListData();
		let routers = window.localStorage.router
		? JSON.parse(window.localStorage.router)
		: [];
		this.buttonList = getButtonList(this.$route.path, routers);
	}
};
</script>
<style lang="stylus" scoped>
.el-table>>>td, .el-table>>>th{
	padding: 8px!important;
}
</style>
